Ember.TEMPLATES["javascripts/discourse-private-topics/connectors/topic-footer-main-buttons-before-create/private-topics-button"] = Ember.HTMLBars.template({"id":null,"block":"{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"siteSettings\",\"private_topics_enabled\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"topic\",\"custom_fields\",\"topic_restricted_access\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"d-button\",null,[[\"icon\",\"action\",\"label\",\"title\"],[\"unlock\",\"controlAccess\",\"private_topics.btn_public_label.title\",\"private_topics.btn_private_label.help\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[28,\"d-button\",null,[[\"icon\",\"action\",\"label\",\"title\"],[\"lock\",\"controlAccess\",\"private_topics.btn_private_label.title\",\"private_topics.btn_private_label.help\"]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}","meta":{"moduleName":"javascripts/discourse-private-topics/connectors/topic-footer-main-buttons-before-create/private-topics-button"}});
define("discourse/plugins/private-topics/discourse-private-topics/connectors/topic-footer-main-buttons-before-create/private-topics-button", ["exports", "discourse/lib/show-modal", "discourse/lib/ajax"], function (_exports, _showModal, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    shouldRender: function shouldRender(args, component) {
      return Discourse.User.currentProp('staff');
    },
    actions: {
      controlAccess: function controlAccess() {
        var container = Discourse.__container__;
        var restrictedAccess = !this.topic.get('custom_fields.topic_restricted_access');
        var model = {
          topic: this.topic,
          restrictedAccess: restrictedAccess
        };
        var controller = container.lookup('controller:private-topics');
        controller.set('model', model);
        controller.send('controlAccess');
      }
    }
  };
  _exports.default = _default;
});
Ember.TEMPLATES["javascripts/discourse-private-topics/connectors/topic-title/private-topics"] = Ember.HTMLBars.template({"id":null,"block":"{\"symbols\":[],\"statements\":[[7,\"span\",true],[8],[7,\"li\",true],[10,\"class\",\"fa fa-lock private-topics-icon\"],[8],[9],[0,\"RESTRICTED ACCESS\"],[9],[0,\"\\n\"]],\"hasEval\":false}","meta":{"moduleName":"javascripts/discourse-private-topics/connectors/topic-title/private-topics"}});
define("discourse/plugins/private-topics/discourse-private-topics/connectors/topic-title/private-topics", ["exports", "discourse/lib/show-modal", "discourse/lib/ajax"], function (_exports, _showModal, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    shouldRender: function shouldRender(args, component) {
      if (args.model.custom_fields.topic_restricted_access === undefined) {
        return false;
      }

      return args.model.custom_fields.topic_restricted_access;
    }
  };
  _exports.default = _default;
});
define("discourse/plugins/private-topics/discourse-private-topics/controllers/private-topics", ["exports", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/plugin-api"], function (_exports, _ajax, _ajaxError, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //import { default as computed } from 'ember-addons/ember-computed-decorators';
  var _default = Ember.Controller.extend({
    actions: {
      controlAccess: function controlAccess() {
        var _this = this;

        //? false : true;
        return (0, _ajax.ajax)("/privatetopic/control_access", {
          type: 'POST',
          data: {
            access_allowed: this.get('model.restrictedAccess'),
            topic_id: this.get('model.topic.id')
          }
        }).then(function (response) {
          if (Discourse.SiteSettings.private_topics_suppress) {
            var path = "/t/" + _this.get('model.topic.id') + '/status'; //Easiest way I could suppress from homepage for now.

            return (0, _ajax.ajax)(path, {
              type: 'PUT',
              data: {
                enabled: !response.success,
                status: 'visible'
              }
            }).then(function () {
              window.location.reload();
            });
          } else {
            window.location.reload();
          }
        }).catch(_ajaxError.popupAjaxError);
      }
    }
  });

  _exports.default = _default;
});

